import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Channel, ChannelType } from '@pixacare/pxc-ts-core';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiScrollbar, TuiTitle } from '@taiga-ui/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { StatusComponent } from '../../shared/status/status.component';
import { ChannelCreateService } from '../channel-create/channel-create.service';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';
import { ChatService } from '../chat.service';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { FilterButtonsComponent } from '@modules/filters/filter-buttons/filter-buttons.component';
import { FilteredSearch } from '@shared/models/filters/filtered-search';
import { channelLabelByType } from '@modules/chat/channel-list/channel-list.config';
import { getUniqueChannelTypesFromChannels } from '@modules/chat/chat.utils';

@Component({
  selector: 'pxc-channel-list',
  standalone: true,
  imports: [
    CommonModule,
    ChannelPreviewComponent,
    TuiLet,
    RouterModule,
    TuiScrollbar,
    TuiButton,
    TuiTitle,
    StatusComponent,
    FilterBarComponent,
    FilterButtonsComponent,
  ],
  templateUrl: './channel-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChannelCreateService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      deps: [ChatService],
      useFactory: (chatService: ChatService) =>
        chatService.channels$.pipe(
          map((channels: Channel[]) => {
            if (!channels.length) {
              return [];
            }
            const uniqueChannelTypes = getUniqueChannelTypesFromChannels(channels);
            if (uniqueChannelTypes.length === 1) {
              return [];
            }
            return [
              {
                type: FilterType.BUTTONS,
                property: 'type',
                name: 'Type',
                defaultValue: uniqueChannelTypes[0],
                getValue: () => of(
                  uniqueChannelTypes.map((channelType) => ({
                    value: channelType,
                    display: channelLabelByType[channelType],
                  })),
                ),
              },
            ];
          }),
        ),
    },
  ],
})
export class ChannelListComponent implements OnInit {

  channels$: Observable<Channel[]>;
  filteredSearch$: Observable<FilteredSearch>;

  Status = Status;

  readonly chatService = inject(ChatService);
  readonly channelCreateService = inject(ChannelCreateService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {

    this.filteredSearch$ = this.filterBarService.filteredSearch$;

    this.channels$ = this.filteredSearch$.pipe(
      switchMap((filteredSearch) => this.chatService.searchChannels$(
        {
          search: filteredSearch.search,
          type: filteredSearch.filters.find((filter) => filter.prop === 'type')?.val as ChannelType,
        },
      )),
    );
  }

  openCreateChannelDialog(): void {
    this.channelCreateService.openCreateChannelDialog();
  }

}
