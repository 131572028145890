import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Media, MediaType } from '@pixacare/pxc-ts-core';
import { TuiCarousel } from '@taiga-ui/kit';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { drawerActions } from 'src/app/shared/store/drawer/drawer.actions';
import { SharedModule } from '../../shared/shared.module';
import { SequenceMediaComponent } from '../sequence-media/sequence-media.component';

@Component({
  selector: 'pxc-sequence-preview-media-carousel',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiCarousel,
    DragDropModule,
    SequenceMediaComponent,
  ],
  templateUrl: './sequence-preview-media-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePreviewMediaCarouselComponent {

  @Input() sequenceId: number;
  @Input() medias: Media[];

  @Output() mediaClicked: EventEmitter<MediaClickedOutput> = new EventEmitter();

  currentThumbnailIndex = 0;

  MediaType = MediaType;

  constructor(
    private readonly store: Store,
  ) { }
  
  dragStart(): void {
    this.store.dispatch(drawerActions.updateIsUserDragging({ isUserDragging: true }));
  }

  dragEnd(): void {
    this.store.dispatch(drawerActions.updateIsUserDragging({ isUserDragging: false }));
  }

  emitMediaClicked(media: Media, idx: number): void {
    this.mediaClicked.emit({
      mediaIdx: idx,
      sequenceId: this.sequenceId,
      clientCode: media.clientCode,
    });
  }

  trackByMediaId(media: Media): number {
    return media.id;
  }

}
