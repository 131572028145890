import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { Client } from '@pixacare/pxc-ts-core';
import { Observable, combineLatest, map, of } from 'rxjs';
import {
  UsersAdministrationTableComponent,
} from 'src/app/modules/administration/users-administration-table/users-administration-table.component';
import { ActionsByUserState } from 'src/app/shared/models/actions-by-user-state';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { CountByKeyPipe } from 'src/app/shared/pipes/count-by-key.pipe';
import { match } from 'src/app/shared/utils/utils';
import {
  AdministrationTableHeaderComponent,
} from '../../administration/administration-table-header/administration-table-header.component';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { SharedModule } from '../../shared/shared.module';
import { ClientAdminService } from '../client-admin/client-admin.service';
import { ClientUsersAdminService } from './client-users-admin.service';

@Component({
  selector: 'pxc-client-users-admin',
  standalone: true,
  imports: [
    CommonModule,
    UsersAdministrationTableComponent,
    AdministrationTableHeaderComponent,
    FilterBarComponent,
    SharedModule,
    CountByKeyPipe,
    LoaderComponent,
  ],
  providers: [
    ClientUsersAdminService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
  ],
  templateUrl: './client-users-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientUsersAdminComponent implements OnInit {

  administrationUsers$: Observable<AdministrationUser[]>;
  searchedAdministrationUsers$: Observable<AdministrationUser[]>;
  client$: Observable<Client>;

  clientUsersActions: ActionsByUserState = {
    [UserAdminState.ENABLED]: [
      {
        id: MenuActionId.CLIENT_ADMIN_USER_DISABLE,
        hint: 'Désactiver l\'utilisateur',
        icon: '@tui.user-minus',
        appearance: 'flat-warning',
        execute: (user: AdministrationUser): void => this.disableUser(user),
      },
    ],
    [UserAdminState.INVITED]: [
      {
        id: MenuActionId.CLIENT_ADMIN_USER_REINVITE,
        hint: 'Renvoyer l\'invitation',
        icon: '@tui.mail',
        execute: (user: AdministrationUser): void => this.resendInvitation(user),
      },
    ],
    [UserAdminState.DISABLED]: [
      {
        id: MenuActionId.CLIENT_ADMIN_USER_ENABLE,
        hint: 'Activer l\'utilisateur',
        icon: '@tui.user-plus',
        appearance: 'flat-success',
        execute: (user: AdministrationUser): void => this.enableUser(user),
      },
    ],
  };

  tableHeaderActions: MenuAction[] = [
    {
      id: MenuActionId.CLIENT_ADMIN_USER_INVITE,
      label: 'Inviter',
      icon: '@tui.user-plus',
      execute: (): void => this.inviteUsers(),
    },
  ];

  tableHeaderActionButtons: ActionButton[] = [
    {
      appearance: 'primary',
      title: 'Inviter',
      icon: '@tui.user-plus',
      actionIds: [
        MenuActionId.CLIENT_ADMIN_USER_INVITE,
      ],
    },
  ];

  private readonly clientUsersAdminService = inject(ClientUsersAdminService);
  private readonly clientAdminService = inject(ClientAdminService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {
    this.administrationUsers$ = this.clientUsersAdminService.loadClientAdministrationUsers$();
    this.client$ = this.clientAdminService.loadClient$();

    this.searchedAdministrationUsers$ = combineLatest([
      this.filterBarService.filteredSearch$,
      this.administrationUsers$,
    ]).pipe(
      map(([{ search }, users]) =>
        users
          .filter((user: AdministrationUser) =>
            match(search, [user.firstName, user.lastName, user.mailAddress]),
          ),
      ),
    );
  }

  adminChange(user: AdministrationUser): void {
    this.clientUsersAdminService.adminChange(user);
  }

  disableUser(user: AdministrationUser): void {
    this.clientUsersAdminService.disableUser(user);
  }

  enableUser(user: AdministrationUser): void {
    this.clientUsersAdminService.enableUser(user);
  }

  resendInvitation(user: AdministrationUser): void {
    this.clientUsersAdminService.resendInvitation(user);
  }

  inviteUsers(): void {
    this.clientUsersAdminService.inviteUsers();
  }

}
