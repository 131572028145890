import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiButton, TuiHint, TuiSurface } from '@taiga-ui/core';
import { Observable, combineLatest, map, of } from 'rxjs';
import { Department } from 'src/app/shared/models/base/department';
import { getDepartmentMembershipsCount } from 'src/app/shared/utils/department-utils';
import { match } from 'src/app/shared/utils/utils';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { SharedModule } from '../../shared/shared.module';
import { ClientDepartmentsAdminService } from './client-departments-admin.service';

@Component({
  selector: 'pxc-client-departments-admin',
  standalone: true,
  imports: [
    CommonModule,
    FilterBarComponent,
    TuiTable,
    TuiButton,
    TuiHint,
    SharedModule,
    TuiSurface,
    LoaderComponent,
  ],
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
    ClientDepartmentsAdminService,
  ],
  templateUrl: './client-departments-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDepartmentsAdminComponent implements OnInit {

  clientDepartments$: Observable<Department[]>;
  columns = ['name', 'membersCount', 'actions'];

  readonly clientDepartmentsAdminService = inject(ClientDepartmentsAdminService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {
    this.clientDepartments$ = combineLatest([
      this.filterBarService.filteredSearch$,
      this.clientDepartmentsAdminService.loadClientDepartments$(),
    ]).pipe(
      map(([{ search }, departments]) =>
        departments
          .map((department: Department) => ({
            ...department,
            membersCount: getDepartmentMembershipsCount(department).toString(),
          }))
          .filter((department: Department) =>
            match(search, [department.name, department.membersCount]),
          ).sort(this.clientDepartmentsAdminService.sortByMembersCount),
      ),
    );
  }

  createGroup(): void {
    this.clientDepartmentsAdminService.openCreateDepartment();
  }

}
