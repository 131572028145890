import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrawerComponent } from '@modules/drawer/drawer.component';
import { ArchivedBadgeComponent } from '@modules/shared/archived-badge/archived-badge.component';
import {
  ArchivedNotificationComponent,
} from '@modules/shared/archived-notification/archived-notification.component';
import { LoaderComponent } from '@modules/shared/loader/loader.component';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiLet } from '@taiga-ui/cdk';
import {
  TuiButton,
  TuiDropdown,
  TuiExpand,
  TuiHint,
  TuiIcon,
  TuiLabel,
  TuiLink,
  TuiLoader,
  TuiNotification,
  TuiScrollbar,
  TuiSurface,
  TuiTitle,
} from '@taiga-ui/core';
import { TuiAvatar, TuiBadge, TuiButtonLoading, TuiElasticContainer, TuiTabs } from '@taiga-ui/kit';
import { TuiCardLarge } from '@taiga-ui/layout';
import { NgChartsModule } from 'ng2-charts';
import { LottieComponent } from 'ngx-lottie';
import { TagInputComponent } from 'src/app/shared/components/tag-input/tag-input.component';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { CalendarPipe } from 'src/app/shared/pipes/calendar.pipe';
import { FilterBarComponent } from '../filters/filter-bar/filter-bar.component';
import { FilterButtonsComponent } from '../filters/filter-buttons/filter-buttons.component';
import { FilterTabsComponent } from '../filters/filter-tabs/filter-tabs.component';
import { LabelTableComponent } from '../label/label-table/label-table.component';
import { LabelComponent } from '../label/label/label.component';
import { PatientModule } from '../patient/patient.module';
import { PaywallAlertComponent } from '../paywall/paywall-alert/paywall-alert.component';
import { SadmModule } from '../sadm/sadm.module';
import { SequenceModule } from '../sequence/sequence.module';
import { SharedModule } from '../shared/shared.module';
import { StatusComponent } from '../shared/status/status.component';
import { TelemonitoringModule } from '../telemonitoring/telemonitoring.module';
import { UserPreviewComponent } from '../user/user-preview/user-preview.component';
import { ClientAddUserComponent } from './client-add-user/client-add-user.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HeaderComponent } from './header/header.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { HomeComponent } from './home/home.component';
import { LargeLayoutComponent } from './large-layout/large-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MediumLayoutComponent } from './medium-layout/medium-layout.component';
import { PatientAnalysesComponent } from './patient-analyses/patient-analyses.component';
import { PatientSequencesComponent } from './patient-sequences/patient-sequences.component';
import { PatientTelemonitoringsComponent } from './patient-telemonitorings/patient-telemonitorings.component';
import { PatientComponent } from './patient/patient.component';
import { PatientsComponent } from './patients/patients.component';
import { SadmComponent } from './sadm/sadm.component';
import { SequencesComponent } from './sequences/sequences.component';
import { SharedtomeComponent } from './sharedtome/sharedtome.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TelemonitoringComponent } from './telemonitoring/telemonitoring.component';
import { TelemonitoringsComponent } from './telemonitorings/telemonitorings.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    MediumLayoutComponent,
    LargeLayoutComponent,
    HomeComponent,
    SequencesComponent,
    PatientComponent,
    PatientsComponent,
    ClientAddUserComponent,
    SharedtomeComponent,
    TelemonitoringComponent,
    TelemonitoringsComponent,
    SadmComponent,
    CalendarPipe,
    HeaderComponent,
    HomeLayoutComponent,
    PatientSequencesComponent,
    PatientTelemonitoringsComponent,
    PatientAnalysesComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    SequenceModule,
    PatientModule,
    LabelTableComponent,
    LabelComponent,
    TelemonitoringModule,
    PaywallAlertComponent,
    DashboardRoutingModule,
    LottieComponent,
    FormsModule,
    ReactiveFormsModule,
    SadmModule,
    NgChartsModule,
    FilterBarComponent,
    FilterTabsComponent,
    FilterButtonsComponent,
    TuiLet,
    TuiLoader,
    TuiButton,
    TuiButtonLoading,
    TuiIcon,
    ...TuiDropdown,
    TuiBadge,
    ...TuiTabs,
    TuiLink,
    ...TuiExpand,
    TuiNotification,
    ...TuiHint,
    SidebarComponent,
    ...TuiTable,
    TuiElasticContainer,
    TuiAvatar,
    UserCardComponent,
    TagInputComponent,
    StatusComponent,
    TuiScrollbar,
    TuiLabel,
    UserPreviewComponent,
    ArchivedBadgeComponent,
    ArchivedNotificationComponent,
    SadmEntityNamePipe,
    DrawerComponent,
    TuiCardLarge,
    TuiSurface,
    TuiTitle,
    LoaderComponent,
  ],
})
export class DashboardModule {}
